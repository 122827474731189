import { createSlice } from "@reduxjs/toolkit"
import { REHYDRATE } from "redux-persist"
export const PageSlice = createSlice({
  name: "pageSlice",
  initialState: {
    pages: [],
    item: [],
    userCart: [],
    Id: "",
    total: "",
    quantity: 1,
  },
  reducers: {
    setPage: (state, action) => {
      state.pages = Object.assign({}, action.payload.childJson)
      const filteredPrice = Object.values(state.pages).filter(
        key => key.id === "12605" || key.id === "11011"
      )

      let pp =
        action.payload.childJson?.length > 0 &&
        action.payload.childJson
          .find(c => c.type === "price")
          ?.content?.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "")

      let shipping =
        action.payload.childJson?.length > 0 &&
        action.payload.childJson
          .find(c => c.type === "shipping")
          ?.content?.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "")
      let cart = {
        name: action.payload.name,
        title: action.payload.title,
        sku: process.env.GATSBY_BUILDSITE?.toLowerCase()?.includes("4dconcepts")
          ? state?.pages[0]?.content
          : action.payload.name,
        code: process.env.GATSBY_BUILDSITE?.toLowerCase()?.includes(
          "4dconcepts"
        )
          ? state?.pages[2]?.content
          : "",
        price: pp
          ? pp
          : filteredPrice &&
            filteredPrice.length > 0 &&
            filteredPrice[0]?.content.replace(
              /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi,
              ""
            ),
        currency: state?.pages[16]?.content?.slice(1, 2),
        quantity: 1,
        color: state?.pages[5]?.content,
        image: action.payload.image,
        url: action.payload.pageUrl,
        shipping: shipping
          ? shipping
          : state.pages &&
            state.pages[16] &&
            state.pages[16].id &&
            state.pages[16].id === "12954" &&
            state.pages[16].content
          ? state.pages[16].content?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
          : 0,
      }

      let listCopy = [...state.item]
      let filteredDataSource = listCopy.filter(item => {
        if (item.sku === cart.sku) {
          return { price: cart.price, shipping: cart.shipping }
        }

        return item
      })

      let check = state.item?.find(page => page.sku === cart.sku)

      if (!check) {
        state.item = [...state.item, cart]
      } else {
        state.item = filteredDataSource
      }
    },
    increase: (state, action) => {
      const cartItem = action.payload.name
        ? state.userCart?.find(item => item.name === action.payload.name)
        : state.userCart?.find(item => item.pageId === action.payload.Id)

      if (cartItem?.name) {
        cartItem.quantity = cartItem.quantity + 1
        state.quantity = 1
      } else {
        state.quantity = state.quantity + 1
      }
    },
    decrease: (state, action) => {
      const cartItem = action.payload.name
        ? state.userCart?.find(item => item.name === action.payload.name)
        : state.userCart?.find(item => item.pageId === action.payload.Id)
      if (cartItem?.name) {
        cartItem.quantity = cartItem.quantity - 1
        state.quantity = 1
      } else {
        state.quantity = state.quantity - 1
      }
    },
    removeItem: (state, action) => {
      const itemName = action.payload
      state.userCart = state.userCart.filter(item => item.name !== itemName)
    },

    clearCart: state => {
      state.item = []
      state.userCart = []
      state.total = ""
    },
    resetQuantity: state => {
      state.quantity = 1
    },

    calculateTotals: state => {
      let shipping = 0
      let total = 0
      state.userCart?.forEach(item => {
        let checkPrice =
          item && item.price && item.price?.includes("$")
            ? item.price.slice(1)
            : item?.price

        if (parseFloat(checkPrice)) {
          total += item.quantity * parseFloat(checkPrice)
          shipping += item.quantity * parseFloat(item.shipping)
        }
      })
      // state.amount = amount
      state.total =
        parseFloat(total?.toFixed(2)) + parseFloat(shipping?.toFixed(2))
    },
    setUserCart: (state, action) => {
      let check = state.userCart.find(item => item.name === action.payload.name)
      if (check?.name) {
        console.log("")
      } else {
        state.userCart = [...state.userCart, action.payload]
      }
      state.quantity = 1
      // if (action.payload.name === "update") {
      //   let findItem = state.item.find(item => item.name === action.payload.Id)
      //   let findMe = state.userCart.find(
      //     item => item?.name === action.payload.Id
      //   )
      //   if (findMe && findMe.name && findItem && findItem.name) {
      //     let listCopy = [...state.userCart]
      //     let filteredDataSource = listCopy.filter(item => {
      //       if (item.name === action.payload.Id) {
      //         item.price = findItem.price
      //       }

      //       return item
      //     })

      //     state.userCart = filteredDataSource
      //   }
      //   return
      // }
      // let findItem = state.item.find(item => item.name === action.payload)
      // if (findItem && findItem.price) {
      //   check = state.userCart.find(item => item.name === findItem.name)
      //   let listCopy = [...state.userCart]
      //   let filteredDataSource = listCopy.filter(item => {
      //     if (item.name === action.payload) {
      //       item.price = findItem.price
      //     }

      //     return item
      //   })

      //   if (!check) {
      //     state.userCart = [...state.userCart, findItem]
      //   } else {
      //     state.userCart = filteredDataSource
      //   }
      // }
    },
    setId: (state, action) => {
      state.Id = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(REHYDRATE, state => {
      if (state.pages) {
        state.pages = state.pages
      }
      state.item = state.item
      state.userCart = state.userCart
      state.total = state.total
    })
  },
})

export const {
  setPage,
  increase,
  decrease,
  removeItem,
  setUserCart,
  clearCart,
  setId,
  calculateTotals,
  resetQuantity,
} = PageSlice.actions

export default PageSlice.reducer
